<template>
  <SearchForm ref="searchFormRef" :formModel="searchController.formModel" @search="tableController.search" :searchLoading="tableController.loading">
    <template #form>
      <el-form-item prop="groupName">
        <el-input placeholder="分类名称" v-model="searchController.formModel.loginName" clearable></el-input>
      </el-form-item>
      <el-form-item prop="disabled">
        <el-select placeholder="状态" v-model="searchController.formModel.disabled" clearable>
          <el-option label="有效" :value="0"></el-option>
          <el-option label="无效" :value="1"></el-option>
        </el-select>
      </el-form-item>
    </template>
    <template #controll>
      <el-button type="primary" @click="clickAdd">新建</el-button>
    </template>
  </SearchForm>
  <ProTable
    :columns="tableController.columns"
    :tableData="tableController.tableData"
    :loading="tableController.loading"
    :total="tableController.total"
    :pageState="tableController.pageState"
    @pageChange="tableController.onPageChange"
  />

  <!-- 模态 -->
  <CateEditModal ref="editModalRef" @successAdd="tableController.search" @successEdit="tableController.refresh" />
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import SearchForm from '@/components/SearchForm'
import ProTable from '@/components/ProTable'
import CateEditModal from './CateEditModal.vue'
import FetchSelect from '@/components/FetchSelect'
import CustomLink from '@/components/customStyle/CustomLink'
import useSearchFormCtrller from '@/hooks/useSearchFormCtrller'
import useTableController from '@/hooks/useTableController'
import { actionConfirm } from '@/utils/confirm'
import request from '@/utils/request'
import { ElMessage } from 'element-plus'

export default {
  setup() {
    const store = useStore()
    const userInfo = computed(() => store.state.user.userInfo)
    const editModalRef = ref(null)
    // searchForm
    const searchController = useSearchFormCtrller({
      formModel: {
        groupName: '',
        disabled: 0,
      },
    })
    // table
    const columns = [
      { label: '分类名称', prop: 'groupName' },
      { label: '排序', prop: 'groupOrder' },
      { label: '状态', prop: 'disabledName' }, // 状态(非空 0-有效 3-失效)
      { label: '创建时间', prop: 'createDateStr' },
      {
        label: '操作',
        prop: 'actions',
        width: 130,
        render: (_, record) => {
          const statusText = record.disabled == '0' ? '失效' : '生效'
          return (
            <div>
              <CustomLink
                onClick={() => {
                  clickEdit(record)
                }}
              >
                编辑
              </CustomLink>
              <CustomLink
                onClick={() => {
                  console.log(record)
                  handleToggleStatus(record)
                }}
              >
                {statusText}
              </CustomLink>
            </div>
          )
        },
      },
    ]

    const tableController = useTableController({
      columns,
      ajax: ({ page, pageSize }) => {
        const postData = {
          page,
          rows: pageSize,
          ...searchController.formModel,
        }
        return request({
          url: '/web/system/videoGroup/getVideoGroupListPaging',
          data: postData,
        }).then((data) => ({ list: data.data ?? [], totalNum: data.rowTop ?? 0 }))
      },
    })

    function clickAdd() {
      editModalRef.value?.open()
    }

    function clickEdit(record) {
      editModalRef.value?.open(record)
    }

    function handleToggleStatus(record) {
      const { groupCode, disabled } = record
      const statusText = disabled == '0' ? '失效' : '生效'
      actionConfirm(`确认${statusText}？`, () => {
        return request({
          url: '/web/system/videoGroup/updateVideoGroupDisabled',
          data: {
            groupCode,
            disabled: disabled == '0' ? '1' : '0',
          },
        }).then(() => {
          tableController.refresh()
          ElMessage.success('操作成功')
        })
      })
    }

    return {
      searchController,
      tableController,
      //
      editModalRef,
      clickAdd,
      userInfo,
    }
  },
  components: { SearchForm, ProTable, CateEditModal, FetchSelect, CustomLink },
}
</script>

<style></style>
