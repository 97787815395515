<template>
  <StyledModal :visible="visible" title="视频分类" @close="close" @submit="submit" :submitLoading="submitLoading">
    <el-form class="form_box" ref="formRef" :model="formModel.form" label-width="90px">
      <el-form-item label="分类名称" prop="groupName" :rules="[{ required: true, message: '请输入分类名称' }]">
        <el-input v-model="formModel.form.groupName" placeholder="请输入分组名称"></el-input>
      </el-form-item>
      <el-form-item
        label="排序"
        prop="groupOrder"
        :rules="[
          { required: true, message: '请输入排序' },
          { type: 'number', min: 1, max: 99999, message: '请输入0-99999之间的数字' },
        ]"
      >
        <el-input v-model.number="formModel.form.groupOrder" placeholder="0-99999之间"></el-input>
      </el-form-item>
    </el-form>
  </StyledModal>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import StyledModal from '@/components/modal/StyledModal'
import FetchSelect from '@/components/FetchSelect'
import request from '@/utils/request'
import { validateFormRef } from '@/common/utils_element'
import ImgUpload from '@/components/ImgUpload'
import FileUpload from '@/components/FileUpload/index.vue'

const defaultForm = {
  groupName: '',
  groupOrder: 1,
}

export default {
  emits: {
    successAdd: null,
    successEdit: null,
  },
  setup(_, ctx) {
    const visible = ref(false)
    const submitLoading = ref(false)

    const formRef = ref(null)
    const formModel = reactive({
      form: {
        ...defaultForm,
      },
    })

    const record = reactive({ v: null })
    const isAdd = computed(() => (record.v ? false : true))

    /**
     * 方法
     */

    async function open(recordParam) {
      visible.value = true
      if (recordParam) {
        getInfo(recordParam?.groupCode)
      }
    }

    const getInfo = async (groupCode) => {
      submitLoading.value = true
      try {
        const data = await request({
          url: '/web/system/videoGroup/getVideoGroupInfo',
          data: {
            groupCode,
          },
        })
        record.v = data
        submitLoading.value = false
        renderForm(data)
      } catch (err) {
        submitLoading.value = false
      }
    }

    function close() {
      record.v = null
      visible.value = false
      formModel.form = {
        ...defaultForm,
      }
    }

    // form回显
    function renderForm(data) {
      formModel.form = data
    }

    // 提交
    async function submit() {
      await validateFormRef(formRef)

      submitLoading.value = true
      // 员工信息
      let data1
      try {
        data1 = await request({
          url: isAdd.value ? '/web/system/videoGroup/createVideoGroup' : '/web/system/videoGroup/updateVideoGroup',
          data: {
            ...formModel.form,
            ...(isAdd.value ? {} : { groupCode: record.v?.groupCode }),
          },
        })
      } catch {
        submitLoading.value = false
      }

      let videoCode = ''
      if (isAdd.value) {
        videoCode = data1.videoCode
      } else {
        videoCode = record.v?.videoCode
      }
      submitLoading.value = false

      // 成功
      isAdd.value && ctx.emit('successAdd')
      !isAdd.value && ctx.emit('successEdit')
      close()
    }

    return {
      visible,
      submitLoading,
      isAdd,
      open,
      close,
      submit,
      //
      formRef,
      formModel,
    }
  },
  components: { StyledModal, FetchSelect, ImgUpload, FileUpload },
}
</script>

<style lang="less" scoped>
.form_box {
  width: 90%;
}
</style>
